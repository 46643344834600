import React from 'react';
import style from './style.module.css';

export default function AltBanner() {
  return (
    <div className={style.container}>
      <h1 className={style.h1}>Welcome to Summit Wealth</h1>
      <p className={style.p}>
        One of the UK's Premier Mortgage and Protection specialists
      </p>
    </div>
  );
}
