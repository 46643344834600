import React from 'react';
import style from './style.module.css';

export default function GridItem({ title, href, image = null }) {
  return (
    <a
      className={style.gridItem}
      href={href}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)), url(${image})`,
      }}
    >
      <h2 className={style.gridItemTitle}>{title}</h2>
    </a>
  );
}
