import React from 'react';
import style from './style.module.css';
import GridItem from './GridItem';

export default function ServicesGrid() {
  return (
    <div className={style.grid}>
      <GridItem
        href="/home-movers"
        title="Moving Home"
        image="http://summitwealth.resknow.co/assets/services/moving-home.jpeg"
      />
      <GridItem
        href="/remortgage"
        title="Remortgaging"
        image="http://summitwealth.resknow.co/assets/services/re-mortgaging.jpeg"
      />
      <GridItem
        href="/buy-to-let"
        title="Buy to Let"
        image="http://summitwealth.resknow.co/assets/services/buy-to-let.jpeg"
      />

      <GridItem
        href="/commercial"
        title="Bridging Finance"
        image="http://summitwealth.resknow.co/assets/services/bridging-loans.jpeg"
      />
      <GridItem
        href="/commercial"
        title="Development Finance"
        image="http://summitwealth.resknow.co/assets/services/development-finance.jpeg"
      />
      <GridItem
        href="/first-time-buyers"
        title="First Time Buyer"
        image="http://summitwealth.resknow.co/assets/services/first-time-buyers.jpeg"
      />

      <GridItem
        href="/home-movers"
        title="Help to Buy"
        image="http://summitwealth.resknow.co/assets/services/help-to-buy.jpeg"
      />
      <GridItem
        href="/equity-release"
        title="Equity Release"
        image="http://summitwealth.resknow.co/assets/services/equity-release.jpeg"
      />
      <GridItem
        href="/commercial"
        title="Commercial Loans"
        image="http://summitwealth.resknow.co/assets/services/commercial-loans.jpeg"
      />

      <GridItem
        href="/protection"
        title="Life Cover"
        image="http://summitwealth.resknow.co/assets/services/life-assurance.jpg"
      />
      <GridItem
        href="/protection"
        title="Critical Illness Cover"
        image="http://summitwealth.resknow.co/assets/services/critical-illness.jpeg"
      />
      <GridItem
        href="/protection"
        title="Income Protection"
        image="http://summitwealth.resknow.co/assets/services/income-protection.jpeg"
      />
    </div>
  );
}
